function customSelect() {
  const selects = document.querySelectorAll(".touchpoint select");
  if (selects.length === 0) {
    console.warn("Nessuna select trovata nella pagina.");
    return;
  }

  selects.forEach((select) => {
    // Rimuovi l'attributo required dalla select originale

    const label = select.nextElementSibling;
    const isLabel = label && label.tagName.toLowerCase() === "label";

    const existingDropdown = isLabel
      ? label.nextElementSibling
      : select.nextElementSibling;
    if (existingDropdown && existingDropdown.classList.contains("dropdown")) {
      existingDropdown.remove();
    }

    const customDiv = document.createElement("div");
    customDiv.classList.add("component", "dropdown");

    const selectedButton = document.createElement("div");
    selectedButton.classList.add("form-control", "custom-dropdown-toggle");
    selectedButton.textContent =
      select.options[select.selectedIndex]?.text || "";

    customDiv.appendChild(selectedButton);

    const dropdownMenu = document.createElement("div");
    dropdownMenu.classList.add("dropdown-menu", "custom-dropdown-menu");

    const searchInput = document.createElement("input");
    searchInput.type = "text";
    searchInput.classList.add("form-control", "custom-search-input");
    searchInput.placeholder = "Cerca...";
    dropdownMenu.appendChild(searchInput);

    const optionsContainer = document.createElement("ul");
    optionsContainer.classList.add("options-container");
    dropdownMenu.appendChild(optionsContainer);

    // Tooltip di errore
    const tooltip = document.createElement("div");
    tooltip.classList.add("tooltip-error", "d-none");
    tooltip.textContent = "Questo campo è obbligatorio.";
    customDiv.appendChild(tooltip);

    const updateOptions = () => {
      optionsContainer.innerHTML = "";
      Array.from(select.options).forEach((option) => {
        const optionItem = document.createElement("li");

        const optionLink = document.createElement("div");
        optionLink.classList.add("dropdown-item", "custom-dropdown-item");
        optionLink.textContent = option.text;

        if (option.disabled) {
          optionLink.classList.add("disabled");
          optionLink.setAttribute("aria-disabled", "true");
        } else {
          optionLink.addEventListener("click", () => {
            selectedButton.textContent = option.text;
            select.value = option.value;

            const event = new Event("change");
            select.dispatchEvent(event);

            dropdownMenu.classList.remove("show");

            // Nascondi il tooltip dopo la selezione
            tooltip.classList.add("d-none");
            customDiv.setAttribute("aria-invalid", "false");
          });
        }

        optionItem.appendChild(optionLink);
        optionsContainer.appendChild(optionItem);
      });
    };

    updateOptions();

    selectedButton.addEventListener("click", () => {
      dropdownMenu.classList.toggle("show");
    });

    searchInput.addEventListener("input", (event) => {
      const searchTerm = event.target.value.toLowerCase();
      Array.from(optionsContainer.children).forEach((item) => {
        const text = item.textContent.toLowerCase();
        if (text.includes(searchTerm)) {
          item.style.display = "block";
        } else {
          item.style.display = "none";
        }
      });
    });

    document.addEventListener("click", (event) => {
      if (!customDiv.contains(event.target)) {
        dropdownMenu.classList.remove("show");
      }
    });

    select.style.position = "absolute";
    select.style.left = "-9999px";
    select.style.opacity = "0";
    select.style.height = "0";
    select.style.overflow = "hidden";
    
    if (isLabel) {
      label.insertAdjacentElement("afterend", customDiv);
    } else {
      select.insertAdjacentElement("afterend", customDiv);
    }

    customDiv.appendChild(dropdownMenu);

    const observer = new MutationObserver(updateOptions);
    observer.observe(select, { childList: true, subtree: true });
  });

  // Intercetta il submit del form
  const forms = document.querySelectorAll("form");
  forms.forEach((form) => {
    form.addEventListener("submit", (event) => {
      let isValid = true;

      selects.forEach((select) => {
        const customDiv = select.nextElementSibling;
        const tooltip = customDiv.querySelector(".tooltip-error");

        if (!select.value || select.value === "") {
          isValid = false;

          // Mostra il tooltip e imposta aria-invalid
          tooltip.classList.remove("d-none");
          customDiv.setAttribute("aria-invalid", "true");
        } else {
          // Nascondi il tooltip e rimuovi aria-invalid
          tooltip.classList.add("d-none");
          customDiv.setAttribute("aria-invalid", "false");
        }
      });

      if (!isValid) {
        event.preventDefault(); // Blocca il submit se il form non è valido
      }
    });
  });
}

export default customSelect;
