import { Modal } from "bootstrap";
import { Collapse } from "bootstrap";
import LocationManager from "./LocationManager";
import customSelect from "./customselect";

class UXManager {
  constructor() {
    this.addListeners();
  }

  isEditing() {
    return !!$("#ccm-menu-click-proxy").length;
  }

  addListeners() {
    $(window).on("scroll resize", this.onScroll);
    $(window).trigger("scroll");
    $(document).ready(this.onReady.bind(this));

    $(".tile.banner.position_.event")
      .each(function (index) {
        $(this).attr("data-id", index);
      })
      .on("click", function () {
        let container = $(".zoomedimage");
        let imageSrc = $(this).find(".image").attr("data-bg");
        if ($(".zoomedimage").length == 0) {
          $("body")
            .append(
              '<div class="zoomedimage tile banner position_ event"></div>'
            )
            .css("overflowY", "hidden");
          container = $(".zoomedimage");
        }
        container
          .css("backgroundImage", "url(" + imageSrc + ")")
          .attr("data-rel", $(this).attr("data-id"))
          .hide()
          .fadeIn(500);
      });

    $(document).on("click", ".zoomedimage", function () {
      $("body").css("overflowY", "scroll");
      $(this).fadeOut(500);
    });
  }

  onScroll() {
    const animation_elements = $(".animation-element");

    const window_height = $(window).height();
    const window_top_position = $(window).scrollTop();
    const window_bottom_position = window_top_position + window_height;

    if (window_top_position == 0) {
      $("body").removeClass("scrolled");
    } else {
      $("body").addClass("scrolled");
    }

    $.each(animation_elements, function () {
      const element_height = $(this).outerHeight();
      const element_top_position = $(this).offset().top;
      const element_bottom_position = element_top_position + element_height;

      if (
        element_bottom_position >= window_top_position &&
        element_top_position <= window_bottom_position
      ) {
        $(this).addClass("in-view");
      } else {
        $(this).removeClass("in-view");
      }
    });

    // fix to show shearchsystem on menu
    if (document.getElementsByClassName("searchsystem").length >= 1) {
      var firstSearchBar = document.getElementsByClassName("searchsystem")[1];

      if (typeof firstSearchBar == typeof undefined) return;

      if (firstSearchBar.offsetTop < $(window).scrollTop()) {
        if ($(".navsearchcontainer").hasClass("d-none")) {
          $(".navsearchcontainer").removeClass("d-none");
        }
        if (!$(".navlinkscontainer").hasClass("d-none")) {
          $(".navlinkscontainer").addClass("d-none");
        }
      } else {
        if (!$(".navsearchcontainer").hasClass("d-none")) {
          $(".navsearchcontainer").addClass("d-none");
        }
        if ($(".navlinkscontainer").hasClass("d-none")) {
          $(".navlinkscontainer").removeClass("d-none");
        }
      }
    }
  }

  onReady() {
    // jQuery('#form1').submit();

    this.convertImgToSvg();
    this.activateTabsets();
    this.animateKeyNumbers();
    this.activateCustomSelect();
    this.activateCalendarTrigger();
    this.activateDrawer();
    this.activateLazyContentLists();
    this.activateZoomables();
    this.activateLoadMore();
    this.activateDestinationLinks();
    this.activateHamburger();
    this.fixHPUX();
    this.fixClubUX();
    this.fixAggregatorUX();
    this.fixCartoucheUX();
    this.activateContactTabs();
    this.monitorTouchpontSubmit();
    this.activateFormSteps();
    this.activateTileTargetNavigation();
    this.reverseLabelAndInput();
    this.languageSwitcher();
    this.contentListFilter();
    this.populateTilePopup();
    this.addIdtoAccordion();
    this.updateComponentVisibility();
    this.activateCountdown();
    this.openNewsletterPopup();
    this.inputTelValidation();
    this.initModalButtonTouchpoints();
    this.initializeDropdownBehavior();
    this.targetBlankExternalLinks();

    const locationManager = new LocationManager();
    locationManager.initialize();

    customSelect();
    document.addEventListener("change", (event) => {
      if (event.target.matches(".touchpoint select")) {
        customSelect();
      }
    });

    this.initConditionalFields(
      "input[name='custom[clients]']", // Selettore trigger
      (trigger) => trigger.checked && ["si", "yes"].includes(trigger.value)
    );

    // sel pagina contiene areaClientiTabContent attiva loginForm
    if (document.querySelector("#areaClientiTabContent")) {
      this.initializeAreaClienti();
    }

    const form = document.querySelector("form.touchpoint"); // seleziona il form
    const buttons = document.querySelectorAll('a[href="#scrollToForm"]'); // seleziona tutti i bottoni/link

    buttons.forEach((button) => {
      button.addEventListener("click", function (e) {
        e.preventDefault();
        form.scrollIntoView({ behavior: "smooth" });
      });
    });
  }

  convertImgToSvg() {
    $("img.svg").each(function () {
      const img = $(this);
      const imgID = img.attr("id");
      const imgClass = img.attr("class");
      const imgURL = img.attr("src");
      const altText = img.attr("alt");
      $.get(
        imgURL,
        function (data) {
          let svg = $(data).find("svg");
          if (typeof imgID !== "undefined") {
            svg = svg.attr("id", imgID);
            svg = svg.attr("alt", altText);
          }
          if (typeof imgClass !== "undefined") {
            svg = svg.attr("class", imgClass + " replaced-svg");
          }
          svg = svg.removeAttr("xmlns:a");
          if (!svg.attr("viewBox") && svg.attr("height") && svg.attr("width")) {
            svg.attr(
              "viewBox",
              "0 0 " + svg.attr("height") + " " + svg.attr("width")
            );
          }
          img.replaceWith(svg);
        },
        "xml"
      );
    });
  }

  activateTabsets() {
    let foundts = [];
    $("[data-tabset]").each(function () {
      const tsn = $(this).attr("data-tabset");
      const trn = $(this).attr("data-tabrel");
      const tab = $('[data-tab="' + trn + '"]');

      $(tab).on("click", function (e) {
        ux.clickTabsetTab(this);
      });

      if (!foundts.includes(tsn)) {
        foundts.push(tsn);
        $(this).show();

        tab.addClass("active");
      } else {
        if (!ux.isEditing()) {
          $(this).hide();
        }
        tab.removeClass("active");
      }
    });
  }

  clickTabsetTab(el) {
    const trn = $(el).attr("data-tab");
    const tabc = $('[data-tabrel="' + trn + '"]');
    const tsn = tabc.attr("data-tabset");

    $('[data-tabset="' + tsn + '"]').each(function () {
      const trn = $(this).attr("data-tabrel");
      const tab = $('[data-tab="' + trn + '"]');

      tab.removeClass("active");
      $(this).hide();
    });

    $(el).addClass("active");
    tabc.show();
  }
  initConditionalFields(triggerSelector, conditionCallback) {
    const triggers = document.querySelectorAll(triggerSelector);

    triggers.forEach((trigger) => {
      console.log(triggers);

      trigger.addEventListener("change", () => {
        const parent = trigger.closest(".form-group");
        const target = parent?.nextElementSibling;

        if (target && target.classList.contains("form-group")) {
          if (conditionCallback(trigger)) {
            target.style.display = "";
          } else {
            target.style.display = "none";
          }
        }
      });

      // Imposta lo stato iniziale
      const parent = trigger.closest(".form-group");
      const target = parent?.nextElementSibling;

      if (target && target.classList.contains("form-group")) {
        target.style.display = conditionCallback(trigger) ? "" : "none";
      }
    });
  }

  animateKeyNumbers() {
    $(".key-number").each(function () {
      var $this = $(this);

      const countTo = $(this).children("span").text();

      if ($this.hasClass(".key-number-animated")) {
        $({ countNum: 1 }).animate(
          {
            countNum: countTo,
          },
          {
            duration: 2000,
            easing: "swing",
            step: function () {
              const l = $("html").attr("lang");
              const s = $this.children("span");
              let t = Math.floor(this.countNum).toLocaleString(l);
              if (s.attr("data-append")) t += s.attr("data-append");
              s.text(t);
            },
            complete: function () {
              const l = $("html").attr("lang");
              const s = $this.children("span");
              let t = this.countNum.toLocaleString(l);
              if (s.attr("data-append")) t += s.attr("data-append");
              s.text(t);
            },
          }
        );
      } else {
        const l = $("html").attr("lang");
        const s = $this.children("span");
        let t = Math.floor(countTo).toLocaleString(l);
        if (s.attr("data-append")) t += s.attr("data-append");
        s.text(t);
      }
    });
  }

  activateCustomSelect() {
    $(document).on(
      "click focus",
      ".component.form-element.select:not(.status-disabled)",
      function (e) {
        if ($(e.target).hasClass("search-element")) return;
        if ($(e.target).parent().hasClass("search-element")) return;

        if (window.ss.pro) {
          if ($(this).children(".optionpositioner").is(":visible")) {
            //console.log('NON PREPARO',  $(this).hasClass('activity') ? 'activity' : 'address');
            return;
          }
          //console.log('PREPARO IL TIMEOUT DI APERTURA',  $(this).hasClass('activity') ? 'activity' : 'address');
          setTimeout(
            $.proxy(function () {
              const op = $(this).children(".optionpositioner");
              const sf = $(this).children('[data-role="search"]');

              if (op.is(":visible")) {
                //console.log('ESCO DAL TIMEOUT DI APERTURA PERCHÈ VISIBILE',  $(this).hasClass('activity') ? 'activity' : 'address');
                return;
              }

              //console.log('ENTRO NEL TIMEOUT DI APERTURA', $(this).hasClass('activity') ? 'activity' : 'address');
              if (sf.hasClass("focus")) {
                //console.log('||||||||||||||||||||');
                //console.log('procedo a aprire', $(this).hasClass('activity') ? 'activity' : 'address');
                op.show();
                op.closest(".component.searchsystem").addClass("open");

                if ($(window).width() <= 576) {
                  //console.log('------------------------------');
                  //console.log('procedo a selezionare tutto il contenuto del campo', sf);

                  //console.log('||||||||||||||||||||');
                  //console.log('procedo a bloccare lo scroll su body');
                  $("body").css("overflow-y", "hidden");
                  $(sf).select();
                }
              }
            }, this),
            200
          );
        } else if (e.type == "click") {
          const op = $(this).children(".optionpositioner");
          if (op.is(":visible")) {
            //console.log('|||||||||||||||||||| click focus');
            //console.log('procedo a chiudere', op);

            op.hide();
          } else {
            //console.log('|||||||||||||||||||| click focus');
            //console.log('procedo a aprire', op);

            op.show();
            op.find('[type="search"]').focus();
          }
        }
      }
    );

    $(document).on(
      "blur",
      ".component.form-element.select:not(.status-disabled)",
      function (e) {
        if (window.ss.pro) {
          window.ss.handleOptionAutoSelect(
            $(this).children(".optionpositioner")
          );

          /*
                setTimeout($.proxy(function () {
                    $(this).children('[data-role="search"]').removeClass('focus');
                    const op = $(this).children('.optionpositioner');
                    op.hide();
                    
                    console.log('|||||||||||||||||||| blur');
                    console.log('procedo a chiudere', op.parent().hasClass('activity') ? 'activity' : 'address');
                }, this), 200)
                */
        }
      }
    );

    $(document).on(
      "click",
      ".component.form-element.select .options li",
      function (el) {
        if ($(this).hasClass("search-unclickable-element")) return;

        $(this).siblings().removeClass("selected");
        $(this).addClass("selected");

        const op = $(this).closest(".component.form-element");
        op.children('input[type="text"]').val($(this).text());
        op.children('input[type="hidden"]').val($(this).attr("data-value"));
      }
    );

    $(document).on("click", function (e) {
      //console.log('#################');
      //console.log('window.ss.pro', window.ss.pro);
      //console.log('$(window).width()', $(window).width());
      //console.log('target dr', $(e.target).attr('data-role'));
      //console.log('target parent dr', $(e.target).parent().attr('data-role'));
      //console.log('condition', ($(e.target).attr('data-role') == 'search' || $(e.target).parent().attr('data-role') == 'search'));

      if (
        window.ss.pro &&
        $(window).width() <= 576 &&
        !(
          $(e.target).attr("data-role") == "search" ||
          $(e.target).parent().attr("data-role") == "search"
        )
      ) {
        //console.log('NON chiudo perché  NON ho un focus su un altro search');
        return;
      } else if (typeof window.ss.pro == typeof undefined) {
        if ($(e.target).hasClass("search-element")) return;
        if ($(e.target).parent().hasClass("search-element")) return;
      }

      const cfe = $(e.target).closest(".component.form-element");
      const op = cfe.find($(".optionpositioner"));

      $(".optionpositioner").each(function () {
        if (!$(this).is(op) && $(this).is(":visible")) {
          //console.log('|||||||||||||||||||| document click');
          //console.log('procedo a chiudere', this);

          $(this).hide();
        }
      });
    });
  }

  activateLazyContentLists() {
    $('.async_content_list:not(".deferred")').each(function () {
      //console.log('00 |||||||||||||||||||| activateLazyContentLists', this);
      window.ux.activateLazyContentList(this);
    });
  }

  activateLazyContentList(el, insertiontarget, insertionmode, contentlevel) {
    //console.log((new Error()).stack);
    //console.log('01 |||||||||||||||||||| activateLazyContentList', $(el));

    //return false;
    const bid = $(el).attr("data-bid");
    let filters = {};
    try {
      filters[bid] = JSON.parse($(el).attr("data-filters"));
    } catch (e) {
      console.error(
        "Errore nel parsing del json filters",
        $(el).attr("data-filters")
      );
      console.log(el);
      return false;
    }

    filters = this.scanForParentselector(filters, bid);
    const id = $(el).attr("data-id");
    const segment = $(el).attr("data-segment");
    const template = $(el).attr("data-template");
    let settings = {};

    try {
      settings = JSON.parse($(el).attr("data-settings"));
    } catch (e) {
      console.error(
        "Errore nel parsing del json settings",
        $(el).attr("data-settings")
      );
      console.log(el);
      return false;
    }

    if (id == "------") return false;
    if (!insertiontarget) insertiontarget = el;
    if (!insertionmode) insertionmode = "replace";

    this.getLazyContentList(
      el,
      bid,
      filters,
      id,
      segment,
      template,
      settings,
      insertiontarget,
      insertionmode,
      contentlevel
    );
  }

  scanForParentselector(filters, bid) {
    if (filters[bid].hasOwnProperty("parentselector")) {
      const parentid = $(filters[bid].parentselector).attr("data-parentid");
      if (typeof parentid !== "undefined") {
        filters[bid].parentid = parentid;
      }
    }

    return filters;
  }

  getLazyContentList(
    el,
    bid,
    filters,
    id,
    segment,
    template,
    settings,
    insertiontarget,
    insertionmode,
    contentlevel
  ) {
    //return false;

    //console.log('02 |||||||||||||||||||| getLazyContentList', $(el));

    const data = {
      filters: filters,
      id: id,
      bid: bid,
      segment: segment,
      template: template,
      settings: settings,
    };

    const options = {
      type: "POST",
      dataType: "html",
      url: "/content/list",
      context: el,
      data: JSON.stringify(data),
      success: function (j) {
        //console.log('03 |||||||||||||||||||| getLazyContentList RESPONSE', $(el));
        if ($(el).hasClass("async_content_list")) {
          window.ux.renderContentList(
            el,
            j,
            bid,
            filters,
            settings,
            insertiontarget,
            insertionmode,
            contentlevel
          );
          window.ux.fixAggregatorUX();
        } else if ($(el).hasClass("drawer")) {
          window.ux.renderDrawer(el, j, bid, filters, settings);
        }
      },
      error: function (j) {},
    };
    $.ajax(options);
  }

  skeletonContentList(el) {
    $(el).find(".tile:not(.skeleton)").addClass("skeleton");
  }

  renderContentList(
    el,
    j,
    bid,
    filters,
    settings,
    insertiontarget,
    insertionmode,
    contentlevel
  ) {
    if (contentlevel == "children") {
      let cj = $.parseHTML(j);
      j = $(cj).children();
    }

    this.insertInDom(insertiontarget, j, insertionmode);

    this.renderCount(el);
    let cardsnum = $(el).find("[data-card]");

    if (
      cardsnum.length == 0 &&
      settings.emptystate == "hide" &&
      $("#ccm-menu-click-proxy").length == 0
    ) {
      $(el).hide();
    } else if (
      cardsnum.length == 0 &&
      settings.emptystate == "hide" &&
      $("#ccm-menu-click-proxy").length > 0
    ) {
      $(el).css("opacity", 0.5);
      const esm =
        '<div class="component emptystate">Content List Empty, will disappear in public mode</div>';

      this.insertInDom(
        el,
        esm,
        settings.emptystatepos ? settings.emptystatepos : "append"
      );
    } else if (cardsnum.length == 0 && settings.emptystate == "message") {
      this.insertInDom(
        el,
        '<div class="component emptystate">' +
          settings.emptystatemessage +
          "</div>",
        settings.emptystatepos ? settings.emptystatepos : "append"
      );
    } else {
      window.ll.update();
    }

    // cerco le contentlist annidate e le attivo
    let near = filters[bid].near;
    $(el)
      .find(".async_content_list:not(.async_content_list_done)")
      .each(function () {
        let f;
        try {
          f = JSON.parse($(this).attr("data-filters"));
          if (f.near) {
            f.near = near;
            $(this).attr("data-filters", JSON.stringify(f));
          }
        } catch (e) {
          console.error(
            "Error parsing JSON in annidated contentlist",
            $(this).attr("data-filters")
          );
          return;
        }

        //return false;
        // lancio l'attivazione corrente

        //console.log('00 |||||||||||||||||||| renderContentList annidated', $(this));
        window.ux.activateLazyContentList(this);

        // disattivo la contentlist per evitare future attivazioni
        $(this).addClass("async_content_list_done");
      });
  }

  insertInDom(target, content, strategy) {
    if (strategy == "prepend") {
      $(target).prepend(content);
    } else if (strategy == "append") {
      $(target).append(content);
    } else if (strategy == "before") {
      $(target).before(content);
    } else if (strategy == "after") {
      $(target).after(content);
    } else if (strategy == "replaceWith") {
      $(target).replaceWith(content);
    } else {
      $(target).html(content);
    }
  }

  renderCount(el) {
    let countel;
    if ($(el).attr("data-countable") == true) {
      countel = el;
    } else {
      countel = $(el).closest('[data-countable="true"]');
    }
    if (countel.length > 0) {
      const shown = $(countel).find(".tile").length;
      const total = $(countel).attr("data-total");
      const delta = total - shown;
      const ds = $(countel).find(".drawerset").first();
      const lt = $(countel).find(".tile").last();

      if (delta > 0 && ds.length > 0 && lt.length > 0) {
        const class_ = lt.attr("class");
        ds.before(
          '<div class="' +
            class_ +
            ' counter"><a href="javascript:return void(0)" onclick="window.ux.proxyDrawerTrigger(this)"><div class="tilehead">+' +
            delta +
            '</div><div class="tiletitle"><h3 class="title">' +
            $(countel).attr("data-countlabel") +
            "</h3></div></a></div>"
        );
      }
    }
  }

  proxyDrawerTrigger(el) {
    $(el).closest(".contentlist").find(".drawertrigger").trigger("click");
  }

  activateDrawer() {
    $(document).on("click", ".drawertrigger", function () {
      const el = $(this).siblings(".drawer").get(0);

      if (!$(el).is(":empty")) {
        window.ux.openDrawer(el, false);
        return false;
      }

      const wr = $(this).closest(".async_content_list");
      const bid = $(wr).attr("data-bid");
      let filters = {};

      filters[bid] = JSON.parse($(wr).attr("data-filters"));
      filters[bid] = window.ux.shapeFiltersDrawer(filters[bid]);
      filters = window.ux.scanForParentselector(filters, bid);

      const id = $(wr).attr("data-id");
      const segment = $(wr).attr("data-segment");
      const template = $(wr).attr("data-template");
      const settings = JSON.parse($(wr).attr("data-settings"));

      window.ux.getLazyContentList(
        el,
        bid,
        filters,
        id,
        segment,
        template,
        settings
      );
    });

    $(document).on("click", ".drawerfader, .drawer .closer", function () {
      window.ux.closeDrawer($(this).closest(".drawerset"));
    });
  }

  shapeFiltersDrawer(filters) {
    filters.listlimit = "100";
    if (JSON.stringify(filters["type"]) == JSON.stringify(["event", "promo"])) {
      delete filters["custom"];
    }
    return filters;
  }

  renderDrawer(el, j, bid, filters) {
    //return false;
    $(el).html(j);

    //hack to avoid recursion
    $(el).find(".drawerset").remove();

    //hack title system
    $(el).find(".contentlist_title > h3").remove();
    $(el).find(".contentlist_title > p").remove();
    $(el).find(".contentlist_title").append('<div class="closer"></div>');

    //hack swiper
    if ($(el).find(".swiper").length > 0) {
      $(el).find(".swiper-controls").remove();
      $(el).find(".swiper").removeClass("swiper").addClass("mask");
      $(el)
        .find(".swiper-wrapper")
        .removeClass("swiper-wrapper")
        .addClass("runner");
    } else {
      $(el)
        .find(".contentlist_title")
        .after('<div class="mask"><div class="runner"></div></div>');
      $(el).find(".tile").appendTo($(el).find(".runner"));
    }

    //hack per prodotti
    $(el)
      .find(".tile.product.standard")
      .removeClass("standard")
      .addClass("medium");

    this.openDrawer(el, true);

    let cardsnum = $(el).find("[data-card]");

    if (
      cardsnum.length == 0 &&
      settings.emptystate == "hide" &&
      $("#ccm-menu-click-proxy").length == 0
    ) {
      $(el).hide();
    } else if (
      cardsnum.length == 0 &&
      settings.emptystate == "hide" &&
      $("#ccm-menu-click-proxy").length > 0
    ) {
      $(el).css("opacity", 0.5);
      $(el).append(
        '<div class="component emptystate">Content List Empty, will disappear in public mode</div>'
      );
    } else if (cardsnum.length == 0 && settings.emptystate == "message") {
      $(el).append(
        '<div class="component emptystate">' +
          settings.emptystatemessage +
          "</div>"
      );
    } else {
      window.ll.update();
    }
  }

  openDrawer(el, wait) {
    if (wait) {
      setTimeout(function () {
        $(el).parent(".drawerset").addClass("open");
      }, 200);
    } else {
      $(el).parent(".drawerset").addClass("open");
    }

    $("body").addClass("modalactive");
  }

  closeDrawer(el) {
    $(el).removeClass("open");
    $("body").removeClass("modalactive");
  }

  activateCalendarTrigger() {
    $(".component.calendar")
      .children(".trigger")
      .on("click", function () {
        $(this).parent().toggleClass("open");
      });
  }

  activateZoomables() {
    $(document).on("click", ".zoomable", function (e) {
      if ($(e.target).attr("role") == "button") return;
      $(this).toggleClass("zoomed");

      if ($(this).hasClass("zoomed")) {
        $(this)
          .find("img[data-original-width]")
          .each(function () {
            setTimeout(
              $.proxy(function () {
                const nh = $(window).height() * 0.8; //$(this).height();
                const ow = $(this).attr("data-original-width");
                const oh = $(this).attr("data-original-height");

                $(this).width((nh * ow) / oh);
                $(this).height(nh);
              }, this),
              500
            );
          });
      } else {
        $(this)
          .find("img[data-original-width]")
          .each(function () {
            $(this).width("");
            $(this).height("");
          });
      }
    });
  }

  activateLoadMore() {
    $(document).on("click", ".loadmore:not(.status-disabled)", function () {
      if ($(this).attr("data-role") == "proxy") {
        const targetid = $(this).attr("data-target");
        $('.loadmore[data-targetid="' + targetid + '"]').trigger("click");
        $(this).removeClass("loadmore").addClass("status-loading");
      } else if ($(this).attr("data-mode") == "paginated") {
        window.ux.loadmorePaginated(this);
      } else {
        window.ux.loadmoreContinuous(this);
      }
    });
  }

  loadmoreContinuous(el) {
    // cambio lo status del bottone
    $(el).removeClass("loadmore").addClass("status-loading");

    // recupero l'elemento con i dati
    let cl = $(el).closest("[data-filters]");

    // incremento il from in filters
    let filters = JSON.parse(cl.attr("data-filters"));
    filters.listfrom = parseInt(filters.listfrom) + parseInt(filters.listlimit);
    cl.attr("data-filters", JSON.stringify(filters));

    // incremento il from in pagination
    let pagination = JSON.parse(cl.attr("data-pagination"));
    pagination.from = parseInt(pagination.from) + parseInt(filters.listlimit);
    cl.attr("data-pagination", JSON.stringify(pagination));

    // carico la prossima pagina
    console.log("00 |||||||||||||||||||| loadmoreContinuous ", $(cl));
    window.ux.activateLazyContentList(cl, el, "replaceWith", "children");
  }

  loadmorePaginated(el) {
    // cambio lo status del bottone
    $(el).removeClass("loadmore").addClass("status-loading");

    // recupero l'elemento con i dati
    let cl = $(el).closest("[data-filters]");

    // modifico il from in filters
    let filters = JSON.parse(cl.attr("data-filters"));
    if ($(el).attr("data-action") == "prev") {
      filters.listfrom =
        parseInt(filters.listfrom) - parseInt(filters.listlimit);
    } else {
      filters.listfrom =
        parseInt(filters.listfrom) + parseInt(filters.listlimit);
    }
    cl.attr("data-filters", JSON.stringify(filters));

    // incremento il from in pagination
    let pagination = JSON.parse(
      $(el).closest(".pagination").attr("data-pagination")
    );
    if ($(el).attr("data-action") == "prev") {
      pagination.from = parseInt(pagination.from) - parseInt(filters.listlimit);
    } else {
      pagination.from = parseInt(pagination.from) + parseInt(filters.listlimit);
    }
    $(el)
      .closest(".pagination")
      .attr("data-pagination", JSON.stringify(pagination));

    // mando in skeleton e svuoto la mappa
    $(el)
      .closest(".contentlist")
      .children(".tile")
      .addClass("skeleton")
      .off("mouseenter")
      .off("mouseleave");

    if (typeof window.gmac != typeof undefined) {
      window.gmac.emptyMarkers();

      if ($(window).width() > 991) {
        $("html, body").animate({ scrollTop: 0 }, 300);
      }
    }

    // carico la prossima pagina
    setTimeout(function () {
      //console.log('00 |||||||||||||||||||| loadmorePaginated ', $(cl));
      window.ux.activateLazyContentList(
        cl,
        $(el).closest(".contentlist"),
        null,
        "children"
      );
    }, 100);
  }

  activateHamburger() {
    $(".hamburger").on("click", function () {
      $(this).toggleClass("open");
      $("nav").find(".menu").toggleClass("open");
    });
  }

  fixHPUX() {
    if ($("body").hasClass("homepage")) {
    }
  }

  fixAggregatorUX() {
    if ($("body").hasClass("aggregator")) {
      if ($(window).width() < 576 && typeof window.gmac != typeof undefined) {
        $("html, body").animate({ scrollTop: 80 }, 300);
        const h = $(window).height() - 57;
        $("section.list").height(h);
        $("section.list").find("aside").height(h);
      }

      $(".fewresults[data-action]").on("click", function () {
        if ($(this).attr("data-action") == "remove-filters") {
          let url = window.location.href;
          window.location.href = url.substring(0, url.lastIndexOf("/"));
        }
      });
    }
  }

  fixCartoucheUX() {
    if ($("body").hasClass("store") && $(window).width() < 768) {
      const l = $(".activityswitcher")
        .find(".component.button")
        .first()
        .attr("href");
      $(".cartouche").find(".component.button").attr("href", l);
    } else if (
      ($("body").hasClass("event") ||
        $("body").hasClass("promo") ||
        $("body").hasClass("product")) &&
      $(window).width() < 768
    ) {
      const l = $(".component.ctainline").attr("href");
      $(".cartouche").find(".component.button").attr("href", l);
    } else {
      //console.log('no need to fix')
    }
  }

  activateContactTabs() {
    if ($(".contact-tabs").length) {
      $(".single-tab").on("click", function (e) {
        var tabToShow = $(this).attr("data-target");
        if (tabToShow == "club") {
          $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .css("background-color", "#F2F7FE");
        } else {
          $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .css("background-color", "#FFF4F4");
        }
        if (!$(this).hasClass("active")) {
          $(".single-tab").removeClass("active");
          $(this).addClass("active");

          $(".single-content").removeClass("active");
          $(".single-content." + tabToShow).addClass("active");
        }
      });
    }
  }

  monitorTouchpontSubmit() {
    $("form.touchpoint").on("submit", function () {
      $(this).find('input[type="submit"]').prop("disabled", true);
    });
  }

  fixClubUX() {
    if ($("body").hasClass("store")) {
      setTimeout(function () {
        if (!$(".body > .subscriptions > .async_content_list").is(":visible")) {
          $('li[data-rel="subscriptions"]').hide();
        }
        if (!$(".body > .activities > .async_content_list").is(":visible")) {
          $('li[data-rel="activities"]').hide();
        }
        if ($(".component.reviews").length == 0) {
          $('li[data-rel="reviews"]').hide();
        }
      }, 2000);
    }
  }

  activateDestinationLinks() {
    $(document).on("click", "[data-destination]", function (e) {
      e.preventDefault();
      e.stopPropagation();
      window.location.href = $(this).attr("data-destination");
    });
  }

  activateFormSteps() {
    if (document.querySelectorAll("form.touchpoint fieldset").length > 0) {
      const form = document.querySelector("form.touchpoint");
      const formSteps = document.querySelectorAll("form.touchpoint fieldset");

      if (formSteps.length === 0) {
        return; // Uscire se non ci sono step nel form
      }

      let currentStep = 0;

      // Funzione per mostrare lo step corrente e nascondere gli altri
      const translations = {
        it: { back: "Indietro", next: "Avanti" },
        es: { back: "Atrás", next: "Siguiente" },
        ca: { back: "Enrere", next: "Següent" },
        fr: { back: "Retour", next: "Suivant" },
        en: { back: "Back", next: "Next" },
      };

      // Funzione per ottenere la lingua corrente del documento
      function getDocumentLanguage() {
        return document.documentElement.lang.slice(0, 2); // Ottiene i primi 2 caratteri della lingua (es: "it", "en", ecc.)
      }

      // Ottieni la lingua corrente del documento
      const currentLanguage = getDocumentLanguage();
      console.log(currentLanguage);
      // Usa le traduzioni appropriate, se la lingua non è supportata, default a "en"
      const backText =
        translations[currentLanguage]?.back || translations["en"].back;
      const nextText =
        translations[currentLanguage]?.next || translations["en"].next;

      console.log(backText, nextText);

      // Aggiungi la barra di avanzamento e i bottoni dinamici in ogni fieldset
      formSteps.forEach((fieldset, index) => {
        // Crea il contenitore per il pulsante "Indietro" e la barra di avanzamento
        const topNavigationWrapper = document.createElement("div");
        topNavigationWrapper.className = "top-navigation";

        // Aggiungi il pulsante "Indietro" sopra la barra di avanzamento
        const backButton = document.createElement("button");
        backButton.type = "button";
        backButton.textContent = backText;
        backButton.className = "back-button";
        backButton.style.display = index === 0 ? "none" : "block"; // Nascondi "Indietro" al primo step
        backButton.setAttribute("data-step", index); // Aggiungi attributo data-step
        topNavigationWrapper.appendChild(backButton);

        // Aggiungi la barra di avanzamento sotto il pulsante "Indietro"
        const progressContainer = document.createElement("div");
        progressContainer.className = "progress-container";

        const progressBar = document.createElement("div");
        progressBar.className = "progress-bar";
        progressBar.style.width =
          ((currentStep + 1) / formSteps.length) * 100 + "%"; // Imposta la larghezza iniziale
        progressContainer.appendChild(progressBar);
        topNavigationWrapper.appendChild(progressContainer);

        // Inserisci il contenitore con "Indietro" e la barra di avanzamento all'inizio del fieldset
        fieldset.insertBefore(topNavigationWrapper, fieldset.firstChild); // Barra di avanzamento in cima

        // Aggiungi bottoni solo se non è l'ultimo step
        if (index < formSteps.length - 1) {
          const navigationWrapper = document.createElement("div");
          navigationWrapper.className = "form-navigation";

          const nextButton = document.createElement("button");
          nextButton.type = "button";
          nextButton.textContent = nextText;
          nextButton.className = "next-button btn cta";
          nextButton.setAttribute("data-step", index); // Aggiungi attributo data-step

          navigationWrapper.appendChild(nextButton);
          fieldset.appendChild(navigationWrapper); // Aggiungi il pulsante "Next" nel fieldset

          // Aggiungi event listener per i bottoni
          backButton.addEventListener("click", function (e) {
            e.preventDefault();
            prevStep();
          });

          nextButton.addEventListener("click", function (e) {
            e.preventDefault();
            nextStep();
          });
        }
      });
      function showStep(stepIndex) {
        formSteps.forEach((step, index) => {
          step.style.display = index === stepIndex ? "block" : "none";
        });

        // Mostra/Nascondi bottoni dinamicamente
        const backButton = formSteps[stepIndex].querySelector(".back-button");
        const nextButton = formSteps[stepIndex].querySelector(".next-button");

        // Gestione del pulsante "Indietro"
        if (stepIndex === 0) {
          backButton.style.display = "none"; // Nascondi "Indietro" al primo step
        } else {
          backButton.style.display = "block"; // Mostra "Indietro" negli altri step
        }

        // Gestione del pulsante "Next"
        if (stepIndex === formSteps.length - 1) {
          nextButton.style.display = "none"; // Nascondi "Next" nell'ultimo step
        } else {
          nextButton.style.display = "block"; // Mostra "Next" negli step precedenti
        }

        // Aggiorna le barre di avanzamento
        const progressBars = document.querySelectorAll("form .progress-bar");
        progressBars.forEach((bar) => {
          bar.style.width = ((stepIndex + 1) / formSteps.length) * 100 + "%";
        });
      }

      // Aggiungi event listener per i bottoni "Indietro" separatamente
      formSteps.forEach((fieldset, index) => {
        const backButton = fieldset.querySelector(".back-button");

        // Assicurati di aggiungere il listener solo se il pulsante esiste
        if (backButton) {
          backButton.addEventListener("click", function (e) {
            e.preventDefault();
            prevStep();
          });
        }
      });

      // Muovi allo step successivo con validazione
      function nextStep() {
        const requiredFields =
          formSteps[currentStep].querySelectorAll("[required]");
        let allValid = true;

        requiredFields.forEach((field) => {
          if (!field.checkValidity()) {
            allValid = false;
            field.reportValidity(); // Mostra il messaggio di validazione
          }
        });

        if (allValid && currentStep < formSteps.length - 1) {
          currentStep++;
          showStep(currentStep);
        }
      }

      // Muovi allo step precedente
      function prevStep() {
        if (currentStep > 0) {
          currentStep--;
          showStep(currentStep);
        }
      }

      // Inizialmente mostra il primo step
      showStep(currentStep);
    }
  }

  activateTileTargetNavigation() {
    const targetListContainer = document.querySelector(".target_list");

    if (targetListContainer) {
      const tiles = Array.from(
        targetListContainer.querySelectorAll(".tile")
      ).reverse(); // Inverti l'ordine delle tile
      const prevButton = document.querySelector(".prev-button");
      const nextButton = document.querySelector(".next-button");
      const contentListFigureImg = document.querySelector(
        ".contentlist figure img"
      ); // Seleziona l'immagine nella figure
      let currentIndex = 0;

      // Funzione per aggiornare lo stato della tile attiva
      function updateActiveTile(index) {
        // Rimuovi la classe active da tutte le tile
        tiles.forEach((tile) => tile.classList.remove("active"));

        // Aggiungi la classe active alla tile corrente
        if (tiles[index]) {
          tiles[index].classList.add("active");

          // Prendi il valore data-bg dell'elemento tilehead della tile corrente
          const tileHead = tiles[index].querySelector(".tilehead div");
          const newBgSrc = tileHead ? tileHead.getAttribute("data-bg") : null;

          // Aggiorna l'immagine se newBgSrc è valido
          if (newBgSrc && contentListFigureImg) {
            contentListFigureImg.src = newBgSrc;
          }
        }

        // Gestisci lo stato dei pulsanti
        prevButton.disabled = index === 0;
        nextButton.disabled = index === tiles.length - 1;
      }

      // Event listener per il pulsante 'Prev'
      prevButton.addEventListener("click", function () {
        if (currentIndex > 0) {
          currentIndex--;
          updateActiveTile(currentIndex);
        }
      });

      // Event listener per il pulsante 'Next'
      nextButton.addEventListener("click", function () {
        if (currentIndex < tiles.length - 1) {
          currentIndex++;
          updateActiveTile(currentIndex);
        }
      });

      // Event listener per cambiare la tile attiva al passaggio del mouse
      tiles.forEach((tile, index) => {
        tile.addEventListener("mouseover", function () {
          currentIndex = index;
          updateActiveTile(currentIndex);
        });
      });

      // Inizializza la tile attiva
      updateActiveTile(currentIndex);
    }
  }
  reverseLabelAndInput() {
    // Seleziona tutti gli elementi con la classe .touchpoint
    const touchpoints = document.querySelectorAll(".touchpoint");

    touchpoints.forEach((touchpoint) => {
      // Trova tutti i .form-group all'interno di ogni .touchpoint
      const formGroups = touchpoint.querySelectorAll(".form-group");

      formGroups.forEach((formGroup) => {
        const label = formGroup.querySelector("label");
        const input = formGroup.querySelector("input, select, textarea");

        // Se entrambi sono presenti e input è ancora figlio di formGroup, invertili
        if (label && input && input.parentNode === formGroup) {
          // Inserisci l'input prima della label
          formGroup.insertBefore(input, label);
        }
      });
      //Ora trova gli input di tipo submit
      const submitButtons = touchpoint.querySelectorAll('input[type="submit"]');

      //aggiungi un wrapper esterno
      submitButtons.forEach((button) => {
        const wrapper = document.createElement("div");
        wrapper.className = "submit-wrapper";
        button.parentNode.insertBefore(wrapper, button);
        wrapper.appendChild(button);
      });
    });
  }

  languageSwitcher() {
    const langSwitcher = document.querySelector(".language-switcher");

    if (langSwitcher) {
      // Aggiungi la classe 'active' al click sul contenitore principale
      langSwitcher.addEventListener("click", function () {
        langSwitcher.classList.add("active");
      });

      const langIcons = langSwitcher.querySelectorAll("svg");

      // Gestisci il comportamento al click sui singoli SVG
      langIcons.forEach((icon) => {
        icon.addEventListener("click", function (event) {
          // Impedire che l'evento si propaghi al contenitore
          event.stopPropagation();

          // Controlla se l'elemento non è selezionato
          if (!icon.classList.contains("selected")) {
            // Ottieni il valore dell'attributo 'data-lang'
            const lang = icon.getAttribute("data-lang");

            // Effettua la redirezione a '/' con il parametro della lingua
            window.location.href = `/${lang}`;
          }
        });
      });
    }
  }
  contentListFilter() {
    // Funzione per ottenere i parametri GET dalla URL
    const getURLParameter = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    // Seleziona tutte le `.component.contentlist` con `.component.filter`
    const contentLists = document.querySelectorAll(".component.contentlist");

    contentLists.forEach((contentList) => {
      const filterComponent = contentList.querySelector(".component.filter");

      if (filterComponent) {
        console.log("Content list con filtro trovata.");
        // Imposta il filtro attivo di default
        this.setupFilterListeners(contentList);

        // Trova i parametri GET per il filtro
        const filterParam = getURLParameter("filter");
        const titleParam = getURLParameter("title");
        const descriptionParam = getURLParameter("description");

        let defaultButton;

        if (filterParam) {
          // Cerca un pulsante con il data-filter corrispondente al parametro
          defaultButton = filterComponent.querySelector(
            `button[data-filter="${filterParam}"]`
          );
        }

        // Se non c'è corrispondenza con il parametro, usa il pulsante di default
        if (!defaultButton) {
          defaultButton =
            filterComponent.querySelector("button.active") ||
            filterComponent.querySelector("button");
        }

        if (defaultButton) {
          const selectedTag =
            defaultButton.getAttribute("data-filter") || filterParam;
          const title = titleParam || defaultButton.getAttribute("data-title");
          const description =
            descriptionParam || defaultButton.getAttribute("data-description");

          // Imposta il pulsante come attivo e applica il filtro
          defaultButton.classList.add("active");
          this.updateContentList(contentList, title, description);
          this.filterTiles(contentList, selectedTag);
        }
      } else {
        console.log("Questa content list non ha un filtro.");
      }
    });
  }

  setupFilterListeners(contentList) {
    const filterButtons = contentList.querySelectorAll(
      ".component.filter button"
    );

    if (filterButtons.length === 0) {
      console.log("Nessun pulsante trovato nel filtro.");
      return;
    }

    // Aggiungi event listener a ciascun pulsante
    filterButtons.forEach((button) => {
      button.addEventListener(
        "click",
        function () {
          const selectedTag = button.getAttribute("data-filter");
          const title = button.getAttribute("data-title");
          const description = button.getAttribute("data-description");

          // Aggiorna titolo e introduzione nella contentlist corrente
          this.updateContentList(contentList, title, description);

          // Toggle della classe `active` sul pulsante cliccato
          filterButtons.forEach((btn) => btn.classList.remove("active"));
          button.classList.add("active");

          // Filtra le tile in base al tag selezionato
          this.filterTiles(contentList, selectedTag);
        }.bind(this)
      );
    });
  }

  updateContentList(contentList, title, description) {
    // Seleziona il titolo e l'introduzione specifici della contentlist corrente
    const dynamicTitle = contentList.querySelector(".contentlist_title .title");
    const dynamicIntroduction = contentList.querySelector(
      ".contentlist_title .subtitle"
    );

    if (dynamicTitle && dynamicIntroduction) {
      dynamicTitle.textContent = title;
      dynamicIntroduction.textContent = description;
    }
  }

  filterTiles(contentList, selectedTag) {
    // Seleziona tutte le `.tile` all'interno della contentlist corrente
    const tiles = contentList.querySelectorAll(".tile");

    tiles.forEach((tile) => {
      const tileTags = tile.getAttribute("data-tags") || "";

      // Condizione speciale: se il tag selezionato è "sedi", includi anche "distributori-gpl"
      if (selectedTag === "sedi" && tileTags.includes("rifornimenti-gpl")) {
        console.log("Sede con rifornimento GPL trovata.");
        tile.classList.remove("d-none"); // Mostra la tile
      }
      // Mostra le tile che corrispondono al tag selezionato
      else if (tileTags.includes(selectedTag)) {
        tile.classList.remove("d-none"); // Mostra la tile
      }
      // Nasconde tutte le altre tile
      else {
        tile.classList.add("d-none"); // Nasconde la tile
      }
    });
  }

  populateTilePopup() {
    //Esegui solo se in pagine è presente una .contentlist.g-tech
    const contentList = document.querySelector(".contentlist.g-tech");
    if (!contentList) {
      console.log("Nessuna contentlist g-tech trovata.");
      return;
    }

    const tiles = document.querySelectorAll(".tile");
    const popupBody = document.getElementById("popup-body");
    const popupElement = document.getElementById("dynamicPopup");

    if (!popupElement) {
      console.error("Elemento popup non trovato");
      return;
    }

    const popup = new Modal(popupElement);

    tiles.forEach((tile) => {
      tile.addEventListener("click", async () => {
        const url = tile.getAttribute("data-url");
        if (!url) return;

        try {
          const response = await fetch(url);
          if (!response.ok)
            throw new Error("Errore nel caricamento del contenuto");

          const html = await response.text();
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = html;

          const mainContent = tempDiv.querySelector(".core"); // Assumi che il contenuto sia in <main>
          popupBody.innerHTML = mainContent
            ? mainContent.innerHTML
            : "<p>Contenuto non trovato.</p>";

          // Mostra il popup
          popup.show();
        } catch (error) {
          console.error(error);
          popupBody.innerHTML = `<p>Errore: ${error.message}</p>`;
          popup.show();
        }
        this.addIdtoAccordion();
      });
    });
  }
  addIdtoAccordion() {
    const accordion = document.querySelectorAll(".component.accordion");

    if (accordion.length === 0) {
      console.log("Nessun accordion trovato.");
      return;
    }

    accordion.forEach((item) => {
      // Trova i figli .accordion-collapse
      const collapse = item.querySelectorAll(".accordion-collapse");
      if (collapse.length > 0) {
        // Recupera il data-bs-parent dal primo elemento figlio
        const parentId = collapse[0].getAttribute("data-bs-parent");
        if (parentId) {
          // Imposta l'ID del genitore con il valore di data-bs-parent
          item.setAttribute("id", parentId.replace("#", ""));

          // Aggiorna anche tutti i figli per garantire che puntino correttamente al genitore
          collapse.forEach((collapseItem) => {
            collapseItem.setAttribute(
              "data-bs-parent",
              `#${parentId.replace("#", "")}`
            );
          });
        }
      }

      // Ricollega il funzionamento del Collapse
      const collapseItems = item.querySelectorAll(".accordion-collapse");
      collapseItems.forEach((collapseItem) => {
        const instance = Collapse.getInstance(collapseItem);
        if (instance) {
          instance.dispose();
        }
        new Collapse(collapseItem, {
          toggle: false, // Mantieni lo stato corrente
        });
      });
    });

    console.log(
      "Accordion aggiornati con nuovi ID e funzionamento ricollegato."
    );
  }
  updateComponentVisibility() {
    // Trova tutti i componenti con classe "component"
    const components = document.querySelectorAll(".component");

    components.forEach((component) => {
      // Cerca l'elemento con ID che termina con "-visibility" all'interno del componente
      const visibilityElement = component.querySelector('[id$="-visibility"]');

      if (visibilityElement) {
        // Leggi l'attributo "visibility"
        const visibility = visibilityElement.getAttribute("visibility");

        if (visibility === "false") {
          //rimuovi il componente
          component.remove();
        } else {
          // Rimuovi l'elemento di visibilità
          visibilityElement.remove();
        }
      } else {
        console.warn(
          `[DEBUG] Component with id "${component.getAttribute(
            "data-id"
          )}" does not have a visibility element.`
        );
      }
    });
  }
  activateCountdown() {
    const countdownElements = document.querySelectorAll(
      ".countdown[class='countdown']"
    );

    countdownElements.forEach((countdownElement) => {
      const targetDate = countdownElement.getAttribute("data-countdown");

      if (!targetDate) {
        console.error(
          "[Countdown] No data-countdown attribute found on element:",
          countdownElement
        );
        return;
      }

      const targetTime = new Date(targetDate).getTime();

      if (isNaN(targetTime)) {
        console.error(
          "[Countdown] Invalid date format in data-countdown:",
          targetDate
        );
        return;
      }

      // Crea gli elementi del countdown
      countdownElement.innerHTML = `
        <div class="countdown-item">
          <span class="countdown-value" data-unit="days">0</span>
          <span class="countdown-label">Giorni</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value" data-unit="hours">0</span>
          <span class="countdown-label">Ore</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value" data-unit="minutes">0</span>
          <span class="countdown-label">Minuti</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value" data-unit="seconds">0</span>
          <span class="countdown-label">Secondi</span>
        </div>
      `;

      const updateCountdown = () => {
        const now = new Date().getTime();
        const timeRemaining = targetTime - now;

        if (timeRemaining <= 0) {
          countdownElement.innerHTML = `
            <div class="countdown-expired">Promozione scaduta!</div>
          `;
          return;
        }

        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        // Aggiorna i valori negli elementi
        countdownElement.querySelector('[data-unit="days"]').textContent = days;
        countdownElement.querySelector('[data-unit="hours"]').textContent =
          hours;
        countdownElement.querySelector('[data-unit="minutes"]').textContent =
          minutes;
        countdownElement.querySelector('[data-unit="seconds"]').textContent =
          seconds;
      };

      // Esegui immediatamente e poi aggiorna ogni secondo
      updateCountdown();
      setInterval(updateCountdown, 1000);
    });
  }

  initializeAreaClienti() {
    function clearAreas() {
      $("iframe[name='frameAC']").attr("src", "about:blank");
    }

    function submitForm(area) {
      clearAreas();

      // Trova il form corrispondente e invia il submit
      const form = $(`#${area}`);
      if (form.length) {
        form.submit();
      } else {
        console.warn(`No form found for area: ${area}.`);
      }
    }

    function handleTabClick(event) {
      const target = $(event.target); // Elemento effettivamente cliccato
      const tab = target.closest("button"); // Risali al pulsante/tab più vicino
      const area = tab.data("area"); // Ottieni il valore di data-area

      console.log(`Tab clicked. Target tab data-area: ${area}`);

      if (area) {
        console.log(`Valid area detected: ${area}. Calling submitForm.`);
        submitForm(area); // Invia in submit il form corrispondente
      } else {
        console.warn(`No area data found on the clicked tab.`);
      }
    }

    // Caricamento predefinito del primo form
    const defaultFormId = "form1";
    console.log(
      `Initializing area clienti. Submitting default form for area: '${defaultFormId}'.`
    );
    submitForm(defaultFormId);

    // Assegna l'evento di click ai pulsanti delle tab
    console.log("Attaching click event handlers to tabs.");
    $("#areaClientiTabs").on("click", "button", handleTabClick);

    const fullscreenButtons = document.querySelectorAll(".fullscreen-btn");
    console.log("Attaching fullscreen button event handlers.");
    const exitFullscreenButtons = document.querySelectorAll(
      ".exit-fullscreen-btn"
    );
    console.log("Attaching exit fullscreen button event handlers.");

    fullscreenButtons.forEach((button) => {
      button.addEventListener("click", function () {
        console.log("Fullscreen button clicked.");
        const iframeName = this.getAttribute("data-iframe");
        const iframe = document.querySelector(`iframe[name="${iframeName}"]`);
        console.log(`Found iframe with name: ${iframeName}`);
        if (iframe) {
          // Rendi l'iframe a tutto schermo
          iframe.style.position = "fixed";
          iframe.style.top = "0";
          iframe.style.left = "0";
          iframe.style.width = "100vw";
          iframe.style.height = "100vh";
          iframe.style.zIndex = "9998";
          iframe.style.border = "none";
          iframe.style.borderRadius = "0";
          iframe.style.overflow = "auto";
          iframe.attributes.scrolling.value = "yes";

          console.log("Fullscreen mode activated.");

          // Nascondi il bottone "Fullscreen" e mostra "Exit Fullscreen"
          this.style.display = "none";
          console.log("Fullscreen button hidden.");
          const exitButton = document.querySelector(
            `.exit-fullscreen-btn[data-iframe="${iframeName}"]`
          );
          console.log("Exit fullscreen button found.");
          exitButton.style.display = "inline-flex";
          console.log("Exit fullscreen button displayed.");
        }
      });
    });

    exitFullscreenButtons.forEach((button) => {
      button.addEventListener("click", function () {
        const iframeName = this.getAttribute("data-iframe");
        const iframe = document.querySelector(`iframe[name="${iframeName}"]`);
        if (iframe) {
          // Riduci l'iframe alla dimensione originale
          iframe.style.position = "";
          iframe.style.top = "";
          iframe.style.left = "";
          iframe.style.width = "";
          iframe.style.height = "";
          iframe.style.zIndex = "";
          iframe.style.border = "";
          iframe.style.borderRadius = "0 1.875rem 1.875rem 0";
          iframe.style.overflow = "";
          iframe.attributes.scrolling.value = "no";

          // Nascondi il bottone "Exit Fullscreen" e mostra "Fullscreen"
          this.style.display = "none";
          const fullscreenButton = document.querySelector(
            `.fullscreen-btn[data-iframe="${iframeName}"]`
          );
          fullscreenButton.style.display = "inline-flex";
        }
      });
    });
  }

  openNewsletterPopup() {
    // Aggiungi il listener per il submit del form nel footer
    const footerForm = document.getElementById("newsletter-form");
    if (footerForm) {
      footerForm.addEventListener("submit", function (e) {
        e.preventDefault(); // Blocca l'invio predefinito del form

        const emailField = document.getElementById("email");
        const email = emailField ? emailField.value : "";

        // Valida l'email
        if (!email || !validateEmail(email)) {
          alert("Per favore, inserisci un'email valida.");
          return;
        }

        // Precompila l'email nel form all'interno della modale
        const modalEmailField = document.querySelector(
          '#newsletterPopup input[name="contact[email]"]'
        );
        if (modalEmailField) {
          modalEmailField.value = email;
        }

        // Mostra la modale
        const modalElement = document.getElementById("newsletterPopup");
        if (modalElement) {
          const modal = new Modal(modalElement);
          modal.show();
        }
      });
    }

    // Aggiungi un listener di click a tutti i bottoni con id "goToNwl"
    const buttons = document.querySelectorAll("#goToNwl");
    buttons.forEach((button) => {
      button.addEventListener("click", function () {
        // Mostra la modale
        const modalElement = document.getElementById("newsletterPopup");
        if (modalElement) {
          const modal = new Modal(modalElement);
          modal.show();
        }
      });
    });

    // Funzione di validazione email
    function validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    }
  }
  initializeDropdownBehavior() {
    const navbarNav = document.getElementById("navbarNav");
    if (!navbarNav) {
      console.error("Elemento #navbarNav non trovato.");
      return;
    }
    const dropdownLinks = document.querySelectorAll(".nav-item.dropdown > a");
    const notDropdownLinks = document.querySelectorAll(
      ".nav-item:not(.dropdown) > a"
    );

    // Chiude tutti i dropdown aperti e rimuove la classe active dai link
    const closeAllDropdowns = () => {
      document
        .querySelectorAll(".nav-item.dropdown .dropdown-menu.show")
        .forEach((menu) => {
          menu.classList.remove("show");
          menu.setAttribute("aria-hidden", "true");
        });
      document
        .querySelectorAll(".nav-item.dropdown > a.active")
        .forEach((activeLink) => {
          activeLink.classList.remove("active");
          activeLink.setAttribute("aria-expanded", "false");
        });
    };

    dropdownLinks.forEach((link) => {
      const navItem = link.parentElement;
      const dropdownMenu = link.nextElementSibling;
      let timeoutId; // ID del timer per il ritardo

      // Inizializza gli attributi ARIA per il link e il menu
      link.setAttribute("aria-haspopup", "true");
      link.setAttribute("aria-expanded", "false");
      if (dropdownMenu) {
        dropdownMenu.setAttribute("aria-hidden", "true");
      }

      // Gestisce il clic su una voce principale
      link.addEventListener("click", (e) => {
        if (dropdownMenu && !dropdownMenu.classList.contains("show")) {
          closeAllDropdowns(); // Chiude eventuali altri dropdown aperti
          dropdownMenu.classList.add("show");
          dropdownMenu.setAttribute("aria-hidden", "false");
          link.classList.add("active");
          link.setAttribute("aria-expanded", "true");
          e.preventDefault(); // Evita la navigazione
        } else {
          closeAllDropdowns(); // Chiude il dropdown aperto e rimuove active
        }
      });

      // Apertura immediata del menu
      navItem.addEventListener("mouseenter", () => {
        clearTimeout(timeoutId); // Cancella il timer di chiusura
        closeAllDropdowns(); // Chiude eventuali altri dropdown aperti
        if (dropdownMenu) {
          dropdownMenu.classList.add("show");
          dropdownMenu.setAttribute("aria-hidden", "false");
          link.classList.add("active");
          link.setAttribute("aria-expanded", "true");
        }
      });

      // Mantiene il menu aperto quando il cursore è sopra il dropdown
      dropdownMenu.addEventListener("mouseenter", () => {
        clearTimeout(timeoutId); // Cancella il timer di chiusura
      });

      // Gestisce la navigazione con tastiera
      link.addEventListener("keydown", (e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          if (dropdownMenu && !dropdownMenu.classList.contains("show")) {
            closeAllDropdowns(); // Chiude eventuali altri dropdown aperti
            dropdownMenu.classList.add("show");
            dropdownMenu.setAttribute("aria-hidden", "false");
            link.classList.add("active");
            link.setAttribute("aria-expanded", "true");
          } else {
            closeAllDropdowns();
          }
        } else if (e.key === "Escape") {
          closeAllDropdowns();
          link.focus(); // Ritorna il focus al link
        }
      });
    });

    notDropdownLinks.forEach((link) => {
      const navItem = link.parentElement;
      navItem.addEventListener("mouseenter", () => {
        closeAllDropdowns();
      });
    });

    navbarNav.addEventListener("mouseout", (event) => {
      // Verifica se il mouse sta uscendo dall'intero nodo (inclusi i discendenti)
      if (!navbarNav.contains(event.relatedTarget)) {
        // Da rifinire, ora si chiude anche se si va nel tendone
        // closeAllDropdowns();
      }
    });

    // Chiude i dropdown quando si clicca al di fuori
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".nav-item.dropdown")) {
        closeAllDropdowns();
      }
    });

    // Gestisce la chiusura del menu con Esc quando è aperto
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeAllDropdowns();
      }
    });
  }
  targetBlankExternalLinks() {
    const internalDomain = window.location.hostname; // Dominio del sito corrente
    const excludedDomain = "magazine.agnenergia.com"; // Dominio da escludere
    const fileExtensions = [
      ".pdf",
      ".doc",
      ".docx",
      ".xls",
      ".xlsx",
      ".ppt",
      ".pptx",
      ".zip",
      ".rar",
    ]; // Estensioni file

    // Seleziona tutti i link della pagina
    const links = document.querySelectorAll("a[href]");

    links.forEach((link) => {
      const url = new URL(link.href, window.location.origin); // Gestione relativa
      const protocol = url.protocol;

      // Verifica se il link è esterno o punta a un file
      const isExternal = url.hostname !== internalDomain;
      const isFile = fileExtensions.some((ext) => url.pathname.endsWith(ext));
      const isExcludedDomain = url.hostname === excludedDomain;
      const isInHeader = link.closest("header"); // Verifica se il link è nell'header
      const isNavLink = link.classList.contains("nav-link"); // Verifica se ha la classe .nav-link
      const isTelOrMailto = protocol === "tel:" || protocol === "mailto:"; // Verifica protocollo

      // Se il link è su magazine.agnenergia.com, rimuove target="_blank" se presente
      if (isExcludedDomain) {
        link.removeAttribute("target");
        link.removeAttribute("rel");
      }
      // Altrimenti, aggiunge target="_blank" solo se è esterno o file, con le eccezioni
      else if (
        (isExternal || isFile) &&
        !isInHeader &&
        !isNavLink &&
        !isTelOrMailto
      ) {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer"); // Migliora la sicurezza
      }

      // Rimuovi l'attributo download dai link .pdf
      if (url.pathname.endsWith(".pdf")) {
        link.removeAttribute("download");
      }
    });
  }
  initDynamicFields(triggerSelector, updateCallback) {
    const triggers = document.querySelectorAll(triggerSelector);

    triggers.forEach((trigger) => {
      trigger.addEventListener("change", () => {
        const parent = trigger.closest(".form-group");
        const target = parent?.nextElementSibling;

        if (target && target.classList.contains("form-group")) {
          updateCallback(trigger, target);
        }
      });

      // Stato iniziale
      const parent = trigger.closest(".form-group");
      const target = parent?.nextElementSibling;

      if (target && target.classList.contains("form-group")) {
        updateCallback(trigger, target);
      }
    });
  }
  validateTouchpointForms() {
    console.log("Validazione form touchpoint");

    // Seleziona tutti i form con classe .touchpoint e attributo data-touchpoint
    const touchpointForms = document.querySelectorAll(
      ".touchpoint[data-touchpoint]"
    );
    console.log("Trovati i form touchpoint", touchpointForms);

    touchpointForms.forEach((form) => {
      // Trova tutte le select richieste nel form
      const requiredSelects = form.querySelectorAll("select[required]");

      requiredSelects.forEach((select) => {
        // Aggiungi un listener per l'evento change
        select.addEventListener("change", () => {
          const formGroup = select.closest(".form-group");
          const dropdownComponent = formGroup?.querySelector(
            ".component.dropdown"
          );
          const dropdownToggle = dropdownComponent?.querySelector(
            ".custom-dropdown-toggle"
          );

          if (select.value && dropdownToggle) {
            dropdownToggle.classList.remove("error"); // Rimuove la classe error
          }
        });
      });

      // Trova il pulsante submit all'interno del form
      const submitButton = form.querySelector('input[type="submit"]');

      if (submitButton) {
        submitButton.addEventListener("click", (event) => {
          let isValid = true;

          requiredSelects.forEach((select) => {
            const formGroup = select.closest(".form-group");
            const dropdownComponent = formGroup?.querySelector(
              ".component.dropdown"
            );
            const dropdownToggle = dropdownComponent?.querySelector(
              ".custom-dropdown-toggle"
            );

            // Controlla se la select ha un valore valido
            if (!select.value) {
              isValid = false;

              // Aggiunge la classe error al .custom-dropdown-toggle
              if (dropdownToggle) {
                dropdownToggle.classList.add("error");
              }
            }
          });

          // Blocca il submit se una select non è valida
          if (!isValid) {
            console.log("Validazione fallita. Blocca il submit.");
            event.preventDefault();
          }
        });
      }
    });
  }

  initModalButtonTouchpoints() {
    const modalButtons = document.querySelectorAll('a[href="#tp-modal"]');

    modalButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault(); // Previene il comportamento di default del link

        // Trova il .touchpoint nella pagina
        const touchpoint = document.querySelector(".touchpoint");
        if (touchpoint) {
          // Trova il primo genitore con la classe sticky
          const stickyParent = touchpoint.closest(".sticky");
          if (stickyParent) {
            // Aggiungi la classe modal al genitore sticky
            stickyParent.classList.add("modal");
          }

          // Crea l'overlay (se non già presente)
          if (!document.querySelector(".touchpoint-overlay")) {
            const overlay = document.createElement("div");
            overlay.classList.add("touchpoint-overlay");

            // Aggiungi l'overlay dopo il touchpoint
            stickyParent.insertAdjacentElement("afterend", overlay);

            // Aggiungi il listener per rimuovere la classe modal
            overlay.addEventListener("click", () => {
              if (stickyParent) {
                stickyParent.classList.remove("modal");
              }
              overlay.remove(); // Rimuove l'overlay
            });
          }
        }
      });
    });

    // Aggiungi l'apertura automatica per alcune pagine
    const autoOpenPages = [
      "/it/risparmia-con-il-fotovoltaico",
      "/it/promozione-impianto-fotovoltaico",
    ];
    const currentPage = window.location.pathname; // Ottieni il percorso della pagina corrente

    if (autoOpenPages.includes(currentPage) && window.innerWidth <= 768) {
      // Controlla se è mobile
      setTimeout(() => {
        const firstModalButton = document.querySelector('a[href="#tp-modal"]');
        if (firstModalButton) {
          // Simula l'hover sul bottone
          const hoverEvent = new Event("mouseover");
          firstModalButton.dispatchEvent(hoverEvent);

          // Simula il clic sul bottone
          firstModalButton.click();
        }
      }, 1000); // Attendi 1 secondo
    }
  }

  inputTelValidation() {
    const phoneInputs = document.querySelectorAll('input[type="tel"]');
    if (phoneInputs.length > 0) {
      const phonePattern =
        /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

      phoneInputs.forEach((input) => {
        // Imposta un placeholder opzionale
        input.placeholder = "Es: +39 123 456 789";

        // Aggiungi validazione on input
        input.addEventListener("input", () => {
          if (!phonePattern.test(input.value)) {
            input.setCustomValidity("Inserisci un numero di telefono valido.");
          } else {
            input.setCustomValidity("");
          }
        });

        // Mostra messaggio personalizzato on invalid
        input.addEventListener("invalid", () => {
          if (!input.value) {
            input.setCustomValidity(
              "Il campo numero di telefono è obbligatorio."
            );
          }
        });
      });
    }
  }
}

export default UXManager;
