import $ from "jquery";
import datepickerFactory from "jquery-datepicker";

window.getClientRects = function () {
  return { top: 0, left: 0 };
};

window.$ = window.jQuery = $;
datepickerFactory($);

import Swiper from "swiper/bundle";
import UXManager from "./libs/ux.js";
import LazyLoadManager from "./libs/lazyload.js";
import CountDownManager from "./libs/countdown.js";
import SwiperManager from "./libs/swiper.js";
import QRCodeManager from "./libs/qrcode.js";
import VideoControlsManager from "./libs/videocontrols.js";
import AccordionManager from './libs/accordionmanager.js';
import InvieportManager from "./libs/inviewport.js";
import GoogleMapsManager from "./libs/googlemaps.js";
import GoogleMapsAsideManager from "./libs/googlemapsaside.js";
import SearchSystemManager from "./libs/searchsystem.js";
import GeolocationManager from "./libs/geolocator.js";
import FAQManager from "./libs/faq.js";
import ModalizerManager from "./libs/modalizer.js";
import bootstrap from "bootstrap";

$(document).ready(function () {
  const ux = new UXManager();
  window.ux = ux;

  $(".countdown").each(function () {
    const cd = new CountDownManager($(this));
  });

  $(".component.qrcode").each(function () {
    const qr = new QRCodeManager($(this));
  });

  // prima faccio partire gli async che conterranno eventualmente anche dei '.component .swiper'
  $(".async_content_list").each(function () {
    //console.log('MAIN - faccio partire asincrono su', $(this));
    const sma = new SwiperManager($(this), true);
  });

  // poi faccio partire i '.component .swiper' non asincroni
  $(".component .swiper").each(function () {
    //console.log('MAIN - guardo il parent di component', $(this).closest('.component').parent());
    if ($(this).closest(".component").parent().hasClass("async_content_list")) {
      //console.log('MAIN - Blocco perché dentro a un async_content_list', $(this));
    } else {
      //console.log('MAIN - faccio partire sincrono su', $(this));
      const sm = new SwiperManager($(this), false);
    }
  });

  $("video.hascustomcontrols").each(function () {
    const vc = new VideoControlsManager($(this));
  });

  $('[data-role="faq"]').each(function () {
    const fm = new FAQManager($(this));
  });

  $(".modalizer").each(function () {
    const modm = new ModalizerManager($(this));
  });

  $("#map_opener").on("click", () => {
    $("#map_opener").addClass("unshow");
    $("#map_blurr").addClass("unshow");
    $(".component.map.googlemaps").removeClass("unshow");
    $(".component.map.googlemaps").addClass("showed");
    let script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBAZEkqnfy411LAXw9gHpuq5xDA0KcSpIc&libraries=places&callback=initMap";
    script.async = true;
    window.initMap = function () {
      $(".component.map.googlemaps").each(function () {
        const gmc = new GoogleMapsManager($(this));
      });
    };
    document.body.appendChild(script);
  });

  if ($(".component.map.googlemapsaside").length > 0) {
    window.gmac = new GoogleMapsAsideManager(
      $(".component.map.googlemapsaside")
    );
  }

  window.ll = new LazyLoadManager();
  window.ivm = new InvieportManager();
  
  const accordionManager = new AccordionManager('.component.accordion > ul > li');

  setTimeout(function () {
    window.ss = new SearchSystemManager();
  }, 500);

  setTimeout(function () {
    window.glm = new GeolocationManager();
  }, 1000);

  const wordsIT = ["il fitness", "lo yoga", "il Pilates", "il tuo business"];
  const wordsES = [
    "el fitness",
    "el wellness",
    "el yoga",
    "el pilates",
    "tu negocio",
  ];
  let currentIndex = 0;

  // Funzione per determinare la lingua della pagina o impostata manualmente
  function getLanguage() {
    //Rileva la lingua della pagina
    const language = document.documentElement.lang;
    return language.startsWith("es") ? "es" : "it"; // Ritorna 'es' per spagnolo, altrimenti 'it'
  }

  let isAnimating = false; // Flag per controllare se un'animazione è in corso

  function changeText() {
    if (isAnimating) return; // Se un'animazione è in corso, esce dalla funzione

    isAnimating = true; // Imposta il flag a true per indicare che l'animazione è iniziata

    const container = document.getElementById("changing-text");
    if (container) {
        const oldText = container.querySelector("span");
        const newText = document.createElement("span");
        const words = getLanguage() === "es" ? wordsES : wordsIT;
        newText.textContent = words[(currentIndex + 1) % words.length];

        // Aggiunge la classe entering per far partire il nuovo testo dal basso
        newText.classList.add("entering");
        container.appendChild(newText);

        // Fa partire l'animazione per il vecchio testo e il nuovo testo
        setTimeout(() => {
          oldText.classList.add("exiting");
          newText.classList.remove("entering");
          newText.classList.add("active");
        }, 50);

        // Rimuove il vecchio testo dopo l'animazione
        setTimeout(() => {
          container.removeChild(oldText);
          isAnimating = false; // Imposta il flag a false per indicare che l'animazione è terminata
        }, 800);

        currentIndex = (currentIndex + 1) % words.length;
    }
  }

  setInterval(changeText, 2500); // Cambia parola ogni 2.5 secondi per dare più tempo all'animazione
});
$(".collapsed").click(function() {
  // toggle the class after half second
  setTimeout(function() {
    $(".navbar-brand svg").addClass("active");
  }, 300);
});

if (document.getElementById("pre-email")) {
  document
    .getElementById("pre-email")
    .addEventListener("submit", function (event) {
      event.preventDefault(); // Previene il comportamento predefinito di invio del form

      // Recupera il valore dell'email dal primo form
      const email = document.getElementById("email").value;

      // Popola il campo email del secondo form cercando l'elemento tramite il suo name contact[email]
      document.querySelector("input[name='contact[email]']").value = email;

      // Invia i dati al tuo server (Concrete5)
      inviaDatiAlServer(email);

      // Scrolla fino al secondo form con name demo
      document.querySelector("a[name='demo']").scrollIntoView({
        behavior: "smooth",
      });
    });
}

// Funzione per inviare i dati al server Concrete5
function inviaDatiAlServer(email) {
  // Dati che vuoi inviare
  const data = {
    spreadsheetId:"1FmNYdlntQF99zwkoRVXCGDb6R1AEym1NS2jHuHkOQq4",
    sheetName:"Partial_email",
    data: new Date().toLocaleDateString(), // Data corrente
    ora: new Date().toLocaleTimeString(),  // Ora corrente
    pagina_acquisizione: window.location.href, // URL corrente
    utm_source: getUTMParam("utm_source"),
    utm_medium: getUTMParam("utm_medium"),
    utm_campaign: getUTMParam("utm_campaign"),
    email: email,

  };

  // Invia la richiesta al tuo server
  fetch('application/tools/spreadsheet-connector.php', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  .then(response => response.json())
  .then(responseData => {
    if (responseData.success) {
      console.log("Dati inviati con successo:", responseData.data);
    } else {
      console.error("Errore dal server:", responseData.message);
    }
  })
  .catch(error => {
    console.error("Errore durante l'invio dei dati:", error);
  });
}

// Funzione per recuperare i parametri UTM dalla URL
function getUTMParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  console.log(urlParams.get(param));
  return urlParams.get(param) || ""; // Ritorna vuoto se non presente
}
var swiper = new Swiper('.swiper-experiences', {
    slidesPerView: 2,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    loop: true
});

var swiper = new Swiper('.gallery.swiperable .swiper', {
  slidesPerView: 4,
  spaceBetween: 30,
  grabCursor: true,
  loop: true,
  KeyboardEvent: true,
  autoplay: {
      delay: 2000,
  },
});


document.addEventListener('DOMContentLoaded', () => {
    const core = document.querySelector('.core'); // Seleziona il div core
    if (core) {
        const firstComponent = core.querySelector('.component:first-of-type'); // Seleziona il primo div.component
        if (firstComponent) {
            const firstChild = firstComponent.firstElementChild; // Controlla il primo figlio del primo .component
            if (firstChild && firstChild.tagName === 'P') {
                firstChild.classList.add('add-padding-top'); // Aggiungi una classe al primo <p>
            }
        }
    }
    
    // Seleziona tutti i div con classe "box"
    const boxes = document.querySelectorAll('.box');
    
    boxes.forEach(box => {
        const firstChild = box.firstElementChild; // Prendi il primo figlio
        if (firstChild && firstChild.classList.contains('text')) {
            box.style.paddingLeft = '0px'; // Imposta il padding-right desiderato
        }
    });
});
