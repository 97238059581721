class AccordionManager {
    constructor(selector) {
        this.accordionItems = document.querySelectorAll(selector);
        this.initialize();
    }

    initialize() {
        this.accordionItems.forEach(item => {
            item.addEventListener('click', (event) => {
                // Ferma la propagazione per evitare interferenze con altri listener
                event.stopPropagation();
                this.toggleAccordion(item);
            });
        });
    }

    toggleAccordion(item) {
        // Trova il primo elemento <ul> figlio di questo <li>
        item.classList.toggle('open');
    }
}

export default AccordionManager;
